import React from "react";
import styled from "styled-components";
// Components
import ProjectBox from "./ProjectBox";
// Assets
import ProjectImg1 from "../../assets/img/projects/1.png";
import ProjectImg2 from "../../assets/img/projects/2.png";
import ProjectImg3 from "../../assets/img/projects/3.png";
import ProjectImg4 from "../../assets/img/projects/4.png";
import ProjectImg5 from "../../assets/img/projects/5.jpg";
import ProjectImg6 from "../../assets/img/projects/6.jpg";
import AddImage2 from "../../assets/img/add/add2.png";
import { useTranslation } from 'react-i18next';

export default function Projects() {
  const { t } = useTranslation();
  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">COMO FUNCIONA?</h1>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg1}
                title="Cadastro de usuário"
                text="Nosso time vai te ajudar a cadastrar sua conta do WhatsApp conosco. Preencha os dados básicos sobre você e escaneie o QR code apresentado no aplicativo do WhatsApp e pronto!"
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg2}
                title={t('Escolha os grupos para compartilhar')}
                text="Coletamos apenas os dados que você deseja compartilhar conosco. Escolha os grupos do WhatsApp que deseja compartilhar conosco e começaremos a coletar dados desses grupos."
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg3}
                title="Dados desidentificados"
                text="Todos os dados são anonimizados com as melhores ferramentas do Google para anonimização, o que significa que nenhuma informação de identificação pessoal é armazenada em nosso servidor. Coletamos dados periodicamente pelo período que você escolher."
              />
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg4}
                title="Estatísticas do painel"
                text="O painel interativo lista todos as conversas individuais e os grupos dos quais você faz parte. Você pode selecionar conversas específicas para compartilhar conosco. O painel também mostra atualizações ao vivo sobre o número de mensagens registradas, contatos doados e usuários ativos da conversa."
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg5}
                title="Dados registrados regularmente"
                text="Coletamos dados periodicamente pelo período que você escolher. Você pode optar por interromper a coleta de dados a qualquer momento. Não armazenaremos nenhum dado após o período que você escolher."
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg6}
                title="Usado para rastrear a desinformação"
                text="A partir daí, esses dados são utilizados pelos pesquisadores para rastrear a disseminação de desinformação e notícias falsas no WhatsApp."
              />
            </div>
          </div>
        </div>
      </div>
      <div className="lightBg large-mt">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src={AddImage2} alt="add" />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
              <h2 className="font40 extraBold mb-2">O que você ganha com isso?</h2>
              <p className="font20">
              Os dados que você fornecer ao projeto serão úteis para identificar a disseminação de conteúdo no WhatsApp, que, de outra forma, seria muito difícil de acessar. Seus dados são anônimos e suas informações pessoais nunca são compartilhadas ou mesmo armazenadas.
              <br />
                
              </p>
              {/* <ButtonsRow
                className="flexNullCenter"
                style={{ margin: "30px 0" }}
              >
                <div style={{ width: "190px" }}>
                  <FullButton
                    title="Get Started"
  
                  />
                </div>
                <div style={{ width: "190px", marginLeft: "15px" }}>
                  <FullButton
                    title="Contact Us"
  
                    border
                  />
                </div>
              </ButtonsRow> */}
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
